<template lang="pug">
  .page.page-photo
    .widget.widget--screen-size
      img.page-photo__cover(src="@/assets/img/photos-bg.jpg")
      .page-photo__content
        .page-photo__content-item
          icon-heart
        .page-photo__content-item here will be our
        .page-photo__content-item wedding photos and videos
        //- template(v-else)
          .page-photo__content-item.page-photo__content-item--no-line ФОТОГРАФИИ УЖЕ ЗДЕСЬ :)
          a.page-photo__link(
            target="_blank"
            href="https://cloud.mail.ru/public/ziaV/fxGTkAYcH") ОТКРЫТЬ
    made-by-invito
</template>

<script>
import IconHeart from '@/components/svg/IconHeart'
import MadeByInvito from '@/components/common/MadeByInvito'

export default {
  name: 'Index',

  components: {
    IconHeart,
    MadeByInvito
  }
}
</script>

<style lang="scss">
  @import '../../sass/abstracts/_variables.scss';

  .page-photo {
    color: $color-white;

    &__cover {
      width: auto;
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__content {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%  ;
      transform: translate(-50%, -50%);
      font-size: 1.4rem;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      display: flex;
      align-items: stretch;
      flex-direction: column;

      > * {
        opacity: 0;
        animation: fadeUp 1.4s cubic-bezier(0.25, 1, 0.5, 1) .3s forwards;
      }

      &-item {
        text-align: center;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: .1rem solid $color-white;

        &--no-line {
          border: none;
        }
      }
    }

    &__link {
      height: 4rem;
      color: $color-white;
      background-color: transparent;
      border: 1px solid $color-white;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &:active {
        color: #333;
        background-color: $color-white;
      }
    }
  }
</style>
